import React, { useEffect, useState } from "react";
import { Navbar } from "../Navbar";
import { Card } from "../Card";
import { List } from "../List";
import { v4 as uuidv4 } from "uuid";
import { Spinner } from "../Spinner";

export const Dashboard = ({ statusCode, token, setStatusCode }) => {
  const [objects, setObjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showGrid, setShowGrid] = useState(true);
  const [objectsTotal, setObjectTotal] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const addBodil = async (objReference, bool) => {
    const res = await fetch(`${process.env.REACT_APP_OBJECT_API}/api/bodil`, {
      method: "PUT",
      body: JSON.stringify({
        objectReference: objReference,
        bodil: bool,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  };

  return (
    <div>
      <Navbar
        objects={objects}
        pageNumber={pageNumber}
        setObjects={setObjects}
        setObjectTotal={setObjectTotal}
        token={token}
        setLoading={setLoading}
        setStatusCode={setStatusCode}
        setShowGrid={setShowGrid}
        showGrid={showGrid}
      />
      <div className="w-full bg-[#fce1cc] py-10 min-h-[65vh]">
        {!loading ? (
          <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-4 gap-8">
            {objects?.length > 0 ? (
              objects?.map((o) => (
                <li
                  key={uuidv4()}
                  className={`${
                    showGrid ? "col-span-1" : "col-span-4"
                  } list-none`}
                >
                  {showGrid ? (
                    <Card loading={loading} addBodil={addBodil} object={o} />
                  ) : (
                    <List loading={loading} addBodil={addBodil} object={o} />
                  )}
                </li>
              ))
            ) : (
              <p className=" w-full text-xl">0 träffar</p>
            )}
          </div>
        ) : (
          <div className="flex justify-center">
            <Spinner />
          </div>
        )}
      </div>
      {objectsTotal !== objects?.length && (
        <div className="w-full text-center ">
          <button
            className="border rounded-full my-8 p-4"
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            Ladda mer
          </button>
        </div>
      )}
    </div>
  );
};
