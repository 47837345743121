import React, { useEffect, useState } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { v4 as uuidv4 } from "uuid";

export const Navbar = ({
  objects,
  pageNumber,
  setObjects,
  setObjectTotal,
  token,
  setLoading,
  setStatusCode,
  showGrid,
  setShowGrid,
}) => {
  const [showSearchBar, setShowSearchBar] = useState(true);
  const [areas, setAreas] = useState([]);
  const [counties, setCounties] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });
  const [state, setState] = useState({
    roomsMin: "-1",
    roomsMax: "6",
    areaMin: "-1",
    areaMax: "50000",
    priceMin: "-1",
    priceMax: "90000000",
    municipality: ["Alla områden"],
    county: "alla",
    sortBy: "dateChanged",
    orderBy: "desc",
    search: [],
    bodil: "new",
    housingType: "HousingCooperative",
  });

  useEffect(() => {
    loadMore(pageNumber);
  }, [pageNumber]);

  const getAllAreas = async (searchString) => {
    const res = await fetch(
      `${process.env.REACT_APP_OBJECT_API}/api/search?searchString=${searchString}`,
      {
        MenuItems: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const result = await res.json();
    setAreas(result);
  };

  const getAllCounties = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_OBJECT_API}/api/countyMunicipality`,
      {
        MenuItems: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const result = await res.json();
    setCounties(result);
  };

  const handleChange = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const searchObjects = async (pageNumber) => {
    setLoading(true);
    const query = getQueryParams(pageNumber);
    const res = await fetch(
      `${process.env.REACT_APP_OBJECT_API}/api/objects/${query}`,
      {
        MenuItems: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const result = await res.json();
    setObjectTotal(result.count);
    setObjects(result.estates);
    setLoading(false);
  };

  const clearNavbar = () => {
    setState({
      roomsMin: "-1",
      roomsMax: "6",
      areaMin: "-1",
      areaMax: "50000",
      priceMin: "-1",
      priceMax: "90000000",
      municipality: ["Alla områden"],
      county: "alla",
      sortBy: "dateChanged",
      orderBy: "desc",
      search: [],
      bodil: "new",
      housingType: "HousingCooperative",
    });
  };

  const loadMore = async (pageNumber) => {
    setLoading(true);
    const query = getQueryParams(pageNumber);
    const res = await fetch(
      `${process.env.REACT_APP_OBJECT_API}/api/objects/${query}`,
      {
        MenuItems: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (!token) {
      setStatusCode(401);
    } else {
      setStatusCode(res.status);
    }
    const result = await res.json();
    setObjectTotal(result.count);
    setObjects(objects.concat(result.estates));
    setLoading(false);
  };

  const getQueryParams = (pageNum) => {
    let result = `?et=${state.housingType}`;
    let querySign = "&";
    if (state.county !== "alla") {
      result = `${result}${querySign}cc=${state.county}`;
      querySign = "&";
    }
    if (state.search.length > 0) {
      state.search
        .filter((s) => s !== "alla")
        .forEach((f) => {
          result = `${result}${querySign}f=${f}`;
          querySign = "&";
        });
    }
    if (state.municipality) {
      state.municipality
        .filter((m) => m !== "Alla områden")
        .forEach((m) => {
          result = `${result}${querySign}mc=${m}`;
          querySign = "&";
        });
    }
    if (state.regions) {
      state.regions
        .map((r) => r.name)
        .forEach((m) => {
          result = `${result}${querySign}reg=${m}`;
          querySign = "&";
        });
    }
    if (state.roomsMin && state.roomsMin !== "-1") {
      result = `${result}${querySign}minR=${state.roomsMin}`;
      querySign = "&";
    }
    if (state.roomsMax && state.roomsMax !== "6") {
      result = `${result}${querySign}maxR=${state.roomsMax}`;
      querySign = "&";
    }

    if (state.areaMin && state.areaMin !== "-1") {
      result = `${result}${querySign}minL=${state.areaMin}`;
      querySign = "&";
    }
    if (state.areaMax && state.areaMax !== "50000") {
      result = `${result}${querySign}maxL=${state.areaMax}`;
      querySign = "&";
    }
    if (state.priceMin && state.priceMin !== "-1") {
      result = `${result}${querySign}minP=${state.priceMin}`;
      querySign = "&";
    }
    if (state.priceMax && state.priceMax !== "90000000") {
      result = `${result}${querySign}maxP=${state.priceMax}`;
      querySign = "&";
    }

    if (state.house) {
      result = `${result}${querySign}et=House`;
      querySign = "&";
    }
    if (state.apartment) {
      result = `${result}${querySign}et=Apartment`;
      querySign = "&";
    }
    if (state.bodil) {
      result = `${result}${querySign}bodil=${state.bodil}`;
      querySign = "&";
    }

    if (state.orderBy) {
      result = `${result}${querySign}ob=${state.orderBy}`;
      querySign = "&";
    }
    if (state.sortBy) {
      result = `${result}${querySign}sb=${state.sortBy}`;
      querySign = "&";
    }
    if (pageNum) {
      result = `${result}${querySign}pn=${pageNum}`;
      querySign = "&";
    }

    return result;
  };

  const getMenuItemString = (MenuItem) => {
    if (MenuItem.search.type === "Län") {
      return `${MenuItem.search.name}`;
    }
    if (MenuItem.search.type === "Kommun") {
      return `${MenuItem.search.name}, ${MenuItem.parent.name}`;
    }
    if (MenuItem.search.type === "Område") {
      return `${MenuItem.search.name}, ${MenuItem.parent.name} kommun`;
    }
    if (MenuItem.search.type === "Stad") {
      return `${MenuItem.search.name}, ${MenuItem.parent.name} kommun`;
    }
    if (MenuItem.search.type === "Gata") {
      return `${MenuItem.search.name}, ${MenuItem.parent.name}`;
    }

    return MenuItem;
  };

  return (
    <>
      <div className="grid grid-cols-7 max-w-7xl  gap-4 justify-center p-4 mx-auto ">
        <div className="w-full col-span-2 flex flex-col justify-start items-start">
          {showSearchBar && (
            <div className="flex items-start flex-col w-full">
              <div className="flex w-full justify-between gap-x-2">
                <label className="block" htmlFor="kommun">
                  Kommun eller län
                </label>
                <button
                  onClick={() => {
                    setShowSearchBar(!showSearchBar);
                    getAllCounties();
                    setState({
                      ...state,
                      region: "",
                      search: [],
                      county: "alla",
                    });
                  }}
                  type="button"
                  className="text-xs "
                >
                  Visa lista
                </button>
              </div>
              <Autocomplete
                id="grouped-autocomplete"
                noOptionsText="Hittade inte området eller adressen"
                limitTags={2}
                multiple
                size="small"
                filterOptions={filterOptions}
                options={areas || []}
                className="w-full"
                onChange={(e, y) => {
                  const d = y.map((x) => x.id);
                  handleChange("search", d);
                }}
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderWidth: "1px",
                    borderColor: "#e46f49",
                  },
                }}
                groupBy={(option) => option.search.type}
                getOptionLabel={(option) => getMenuItemString(option)}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={uuidv4()}>
                      {getMenuItemString(option)}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    onChange={(e) => {
                      getAllAreas(e.target.value);
                    }}
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "1px",
                        borderColor: "#e46f49",
                      },
                    }}
                    className=" w-full rounded"
                    {...params}
                    placeholder="Skriv här"
                  />
                )}
              />
            </div>
          )}
          {!showSearchBar && (
            <div className="flex items-start gap-x-2 w-full">
              <div className="flex items-start flex-col w-full">
                <div className="flex gap-x-2">
                  <label className="block" htmlFor="cars">
                    Län
                  </label>
                </div>
                <Select
                  className="text-start w-full rounded"
                  name="county"
                  size="small"
                  id="county"
                  sx={{
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "1px",
                      borderColor: "#e46f49",
                    },
                  }}
                  value={state.county}
                  onChange={(e) => {
                    setState({ ...state, municipality: [] });
                    handleChange(e.target.name, e.target.value);
                    const municipalities = counties.find(
                      (x) => x.code === e.target.value
                    ).municipalities;
                    setMunicipalities(municipalities);
                  }}
                >
                  <MenuItem value="alla">Alla län</MenuItem>
                  {counties &&
                    counties.map((x) => (
                      <MenuItem key={uuidv4()} value={x.code}>
                        {x.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
              <div className="flex items-start flex-col w-full">
                <div className="flex w-full justify-between">
                  <label htmlFor="cars">Område</label>
                  <button
                    onClick={() => {
                      setShowSearchBar(!showSearchBar);
                      setState({
                        ...state,
                        region: "",
                        search: [],
                        county: "alla",
                      });
                      setMunicipalities([]);
                    }}
                    type="button"
                    className="text-xs "
                  >
                    Visa skrivfält
                  </button>
                </div>
                <Select
                  className="text-start w-full rounded"
                  name="municipality"
                  id="municipality"
                  size="small"
                  value={state.municipality}
                  multiple
                  sx={{
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "1px",
                      borderColor: "#e46f49",
                    },
                  }}
                  onChange={(e) => {
                    handleChange(
                      e.target.name,
                      e.target.value.filter((x) => x !== "Alla områden")
                    );
                  }}
                >
                  <MenuItem value="Alla områden">
                    {municipalities?.length === 0
                      ? "Välj län först"
                      : "Alla kommuner"}
                  </MenuItem>
                  {municipalities.length > 0 &&
                    municipalities?.map((x) => (
                      <MenuItem key={uuidv4()} value={x.code}>
                        {x.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col items-start">
          <label htmlFor="rooms">Rum</label>
          <Select
            size="small"
            className="text-start mb-2 rounded w-full"
            name="roomsMin"
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#e46f49",
              },
            }}
            id="rooms"
            value={state.roomsMin}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          >
            <MenuItem value="-1">Min</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="6">6</MenuItem>
          </Select>
          <Select
            className="text-start mb-2 rounded w-full"
            name="roomsMax"
            id="rooms"
            size="small"
            value={state.roomsMax}
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#e46f49",
              },
            }}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          >
            <MenuItem value="-1">0</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="6">Max</MenuItem>
          </Select>
        </div>
        <div className="flex flex-col items-start">
          <label htmlFor="size">Storlek</label>
          <Select
            className="text-start mb-2 rounded w-full"
            name="areaMin"
            id="area"
            size="small"
            value={state.areaMin}
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#e46f49",
              },
            }}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          >
            <MenuItem value="-1">Min</MenuItem>
            <MenuItem value="20">20</MenuItem>
            <MenuItem value="30">30</MenuItem>
            <MenuItem value="40">40</MenuItem>
            <MenuItem value="50">50</MenuItem>
            <MenuItem value="60">60</MenuItem>
            <MenuItem value="70">70</MenuItem>
            <MenuItem value="80">80</MenuItem>
            <MenuItem value="90">90</MenuItem>
            <MenuItem value="100">100</MenuItem>
            <MenuItem value="110">110</MenuItem>
            <MenuItem value="120">120</MenuItem>
            <MenuItem value="130">130</MenuItem>
            <MenuItem value="140">140</MenuItem>
            <MenuItem value="150">150</MenuItem>
            <MenuItem value="160">160</MenuItem>
            <MenuItem value="170">170</MenuItem>
            <MenuItem value="180">180</MenuItem>
            <MenuItem value="190">190</MenuItem>
            <MenuItem value="200">200</MenuItem>
          </Select>
          <Select
            size="small"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            className="text-start mb-2 rounded w-full"
            name="areaMax"
            value={state.areaMax}
            id="areaMax"
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#e46f49",
              },
            }}
          >
            <MenuItem value="-1">10</MenuItem>
            <MenuItem value="20">20</MenuItem>
            <MenuItem value="30">30</MenuItem>
            <MenuItem value="40">40</MenuItem>
            <MenuItem value="50">50</MenuItem>
            <MenuItem value="60">60</MenuItem>
            <MenuItem value="70">70</MenuItem>
            <MenuItem value="80">80</MenuItem>
            <MenuItem value="90">90</MenuItem>
            <MenuItem value="100">100</MenuItem>
            <MenuItem value="110">110</MenuItem>
            <MenuItem value="120">120</MenuItem>
            <MenuItem value="130">130</MenuItem>
            <MenuItem value="140">140</MenuItem>
            <MenuItem value="150">150</MenuItem>
            <MenuItem value="160">160</MenuItem>
            <MenuItem value="170">170</MenuItem>
            <MenuItem value="180">180</MenuItem>
            <MenuItem value="190">190</MenuItem>
            <MenuItem value="50000">Max</MenuItem>
          </Select>
        </div>
        <div className="flex flex-col items-start">
          <label htmlFor="price">Pris</label>
          <Select
            className="text-start mb-2 rounded w-full"
            name="priceMin"
            id="price"
            size="small"
            value={state.priceMin}
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#e46f49",
              },
            }}
            MenuProps={{
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: "#e46f49",
                  color: "white",
                },
              },
            }}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          >
            <MenuItem value="-1">Min</MenuItem>
            <MenuItem value="100000">100 000</MenuItem>
            <MenuItem value="200000">200 000</MenuItem>
            <MenuItem value="300000">300 000</MenuItem>
            <MenuItem value="400000">400 000</MenuItem>
            <MenuItem value="500000">500 000</MenuItem>
            <MenuItem value="750000">750 000</MenuItem>
            <MenuItem value="1000000">1 000 000</MenuItem>
            <MenuItem value="1500000">1 500 000</MenuItem>
            <MenuItem value="2000000">2 000 000</MenuItem>
            <MenuItem value="2500000">2 500 000</MenuItem>
            <MenuItem value="3000000">3 000 000</MenuItem>
            <MenuItem value="3500000">3 500 000</MenuItem>
            <MenuItem value="4000000">4 000 000</MenuItem>
            <MenuItem value="5000000">5 000 000</MenuItem>
            <MenuItem value="6000000">6 000 000</MenuItem>
            <MenuItem value="7000000">7 000 000</MenuItem>
            <MenuItem value="8000000">8 000 000</MenuItem>
            <MenuItem
              sx={{
                "& .Mui-selected ": {
                  backgroundColor: "#e46f49",
                },
              }}
              value="10000000"
            >
              10 000 000
            </MenuItem>
          </Select>
          <Select
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#e46f49",
              },
              "& .Mui-selected ": {
                backgroundColor: "#e46f49",
              },
            }}
            value={state.priceMax}
            className="text-start mb-2 rounded w-full"
            name="priceMax"
            id="price"
            size="small"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          >
            <MenuItem value="-1">0</MenuItem>
            <MenuItem value="100000">100 000</MenuItem>
            <MenuItem value="200000">200 000</MenuItem>
            <MenuItem value="300000">300 000</MenuItem>
            <MenuItem value="400000">400 000</MenuItem>
            <MenuItem value="500000">500 000</MenuItem>
            <MenuItem value="750000">750 000</MenuItem>
            <MenuItem value="1000000">1 000 000</MenuItem>
            <MenuItem value="1500000">1 500 000</MenuItem>
            <MenuItem value="2000000">2 000 000</MenuItem>
            <MenuItem value="2500000">2 500 000</MenuItem>
            <MenuItem value="3000000">3 000 000</MenuItem>
            <MenuItem value="3500000">3 500 000</MenuItem>
            <MenuItem value="4000000">4 000 000</MenuItem>
            <MenuItem value="5000000">5 000 000</MenuItem>
            <MenuItem value="6000000">6 000 000</MenuItem>
            <MenuItem value="7000000">7 000 000</MenuItem>
            <MenuItem value="8000000">8 000 000</MenuItem>
            <MenuItem value="90000000">Max</MenuItem>
          </Select>
        </div>
        <div className="flex flex-col items-start">
          <label htmlFor="housingType">Bostadstyp</label>
          <Select
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#e46f49",
              },
            }}
            size="small"
            className="text-star p-0 mb-2 rounded w-full"
            name="housingType"
            id="housingType"
            value={state.housingType}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          >
            <MenuItem value="HousingCooperative">Bostadsrätt</MenuItem>
            <MenuItem value="House">Villa/Radhus</MenuItem>
            <MenuItem value="Cottage">Fritidshus</MenuItem>
          </Select>
        </div>
        <div className="flex flex-col items-start">
          <label htmlFor="bodilTag">Bodilstatus</label>
          <Select
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#e46f49",
              },
            }}
            size="small"
            className="text-star p-0 mb-2 rounded w-full"
            name="bodil"
            id="bodil"
            value={state.bodil}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          >
            <MenuItem value="new">Nya</MenuItem>
            <MenuItem value="yes">Godkända</MenuItem>
            <MenuItem value="no">Nekade</MenuItem>
          </Select>
        </div>
      </div>
      <div className="flex w-full max-w-7xl mx-auto justify-end items-center px-4">
        <button
          onClick={() => clearNavbar()}
          className="border-0 bg-[#e46f49] text-white rounded-full p-2 w-20 mr-2"
        >
          Rensa
        </button>
        <button
          onClick={() => searchObjects()}
          className="border-0 bg-[#e46f49] text-white rounded-3xl p-2 w-20"
        >
          Sök
        </button>
      </div>
      <div className="grid grid-cols-14 max-w-7xl gap-4 justify-center p-4 pt-0 mx-auto ">
        <div className="col-span-2 flex flex-col text-start">
          <label htmlFor="sort">Sortera på:</label>
          <Select
            id="sort"
            size="small"
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#e46f49",
              },
            }}
            value={state.sortBy}
            name="sortBy"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          >
            <MenuItem value="dateChanged">Inkommet</MenuItem>
            <MenuItem value="startingPrice">Pris</MenuItem>
            <MenuItem value="monthlyFee">Avgift</MenuItem>
            <MenuItem value="livingSpace">Storlek</MenuItem>
            <MenuItem value="numberOfRooms">Antal rum</MenuItem>
          </Select>
        </div>
        <div className="col-span-2 flex flex-col text-start justify-end">
          <Select
            id="order"
            size="small"
            name="orderBy"
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
                borderColor: "#e46f49",
              },
            }}
            value={state.orderBy}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          >
            <MenuItem value="desc">Högst först</MenuItem>
            <MenuItem value="asc">Lägst först</MenuItem>
          </Select>
        </div>
        <div className="col-span-2 flex items-end col-start-13 gap-x-3">
          <label htmlFor="icons">Visa som:</label>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            onClick={() => setShowGrid(true)}
            className={`${
              showGrid ? "text-[#fce1cc]" : "text-black"
            } w-8 h-8 cursor-pointer`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            id="icons"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            onClick={() => setShowGrid(false)}
            className={`${
              !showGrid ? "text-[#fce1cc]" : "text-black"
            } w-8 h-8 cursor-pointer`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
        </div>
      </div>
    </>
  );
};
